.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-art {
  color: white;
  font-family: monospace;
  font-size: 1rem;
  line-height: 1;
  overflow: auto;
  padding: 1rem;
  white-space: pre;
  position: relative;
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  justify-content: center;
}

#tsparticles {
  position: relative;
}

.box {
  display: flex;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.map-container {
  width: 50%;
  height: 100%;
}

h1 {
  color: white;
  position: relative;
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  justify-content: center;
}

.leaflet-container {
  height: 500px
}

.input-container {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.input-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input-container button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #0056b3;
}

.friend-rank {
  text-align: center;
  margin-top: 20px;
}

.friend-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.friend-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.friend-item button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}

.friend-item button:hover {
  background-color: #218838;
}

.friend-item button:nth-child(4) {
  background-color: #dc3545;
}

.friend-item button:nth-child(4):hover {
  background-color: #c82333;
}

.friend-name {
  font-weight: bold;
}

.friend-pizza-count {
  color: #555;
}

.new-friend {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.new-friend input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.new-friend button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.new-friend button:hover {
  background-color: #0056b3;
}

.custom-page {
  text-align: center;
  padding: 20px;
}

.html-input {
  width: 100%;
  height: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
}

.html-output {
  width: 100%;
  height: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
  overflow: auto;
  background-color: #f9f9f9;
}

.whale-container, .home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.input-container, .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 300px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.nav-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.food-button {
  padding: 10px 20px;
  border: none;
  border-radius: 50%;
  background-color: #ffcc00;
  color: white;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.food-button:hover {
  background-color: #ff9900;
}

.loading-wheel {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.download-link {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.download-link:hover {
  background-color: #218838;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.publish-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.publish-container input,
.publish-container textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 300px;
}

.publish-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.publish-container button:hover {
  background-color: #0056b3;
}

.sheet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.sheet-container .content {
  margin: 20px 0;
  text-align: center;
  color: white;
}